import { above, media } from 'utils/mediaqueries';

import CmsBlocks from 'libs/wordpress/content/CmsBlocks';
import ExpandWrapper from 'components/wrappers/Animations/ExpandWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { mediaQueries } from 'config/theme/breakpoints';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useClientHeight from 'hooks/useClientHeight';

const Wrapper = styled('section')``;

const InnerWrapper = styled('div')`
    display: -webkit-box;
    width: 100%;
    height: 100%;
    overflow-x: scroll;

    ${above.desktopSm} {
        display: flex;
        align-content: normal;
        flex-direction: row;
        overflow-y: hidden;
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Block = styled('div', { shouldForwardProp: prop => ['numberOfColumns'].indexOf(prop) === -1 })`
    width: 75vw;
    height: 100%;
    transition: all ${transitions.primary};

    ${above.desktopSm} {
        width: ${({ numberOfColumns }) => `calc(100vw / ${numberOfColumns})`};

        ${media.hover} {
            &:hover {
                width: ${({ numberOfColumns }) => (numberOfColumns === 3 ? `75vw` : `50vw`)};
            }
        }
    }
`;

const ExpanderLayout = ({ content = [], ...rest }) => {
    const clientHeight = useClientHeight();
    const numberOfColumns = content.length;

    // We should not render the expander layout if the data is not correct.
    if (content.length < 3) {
        return null;
    }

    return (
        <Wrapper height={clientHeight}>
            <ExpandWrapper>
                <InnerWrapper>
                    {content.map((column, index) => (
                        <Block numberOfColumns={numberOfColumns} key={`${column.layout}-${index}`}>
                            <CmsBlocks isColumn data={column} mediaQuery={mediaQueries.twoColumn} {...rest} />
                        </Block>
                    ))}
                </InnerWrapper>
            </ExpandWrapper>
        </Wrapper>
    );
};

ExpanderLayout.propTypes = {
    content: PropTypes.array,
};

export default ExpanderLayout;
